import { render, staticRenderFns } from "./UsersTab.vue?vue&type=template&id=52e6b983&scoped=true&"
import script from "./UsersTab.vue?vue&type=script&lang=js&"
export * from "./UsersTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52e6b983",
  null
  
)

export default component.exports